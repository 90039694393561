.container {
	width: 100% !important;
	max-width: rem(1680px) !important;
	padding: 0 rem(20px);
	margin: 0 auto;

	&.is-small {
		max-width: rem(700px) !important;
	}

	&.is-auto-width {
		max-width: initial !important;
		width: auto !important;
	}
}
