﻿$breadcrumb-item-color: $primary;
$breadcrumb-item-active-color: #95a1bf;

$breadcrumb-item-padding-vertical: 0.75em;
$breadcrumb-item-padding-horizontal: 0.5em;

$breadcrumb-item-separator-color: $breadcrumb-item-color;

@import '~bulma/sass/components/breadcrumb';

.breadcrumb {
	background: $primary-contrast;
	font-size: rem(16px);
	letter-spacing: 0.02em;
	//padding: 0.25rem 1.25rem;

	&:not(:last-child) {
		margin-bottom: 0;
	}

	&.has-succeeds-separator li + li {
		margin-left: 0;

		&:before {
			content: '>';
			position: relative;
			top: 0;
			left: 0;
		}
	}
}