﻿.text-border {
	position: relative;
	display: block;
	color: #fff;
	font-weight: bold;
	padding: 0 0 0 rem(28px);

	strong {
		color: $secondary;
	}

	&:before {
		position: absolute;
		content: '';
		width: rem(7px);
		height: calc(100% - 1.0625rem);
		top: rem(6px);
		left: 0;
		background: $secondary
	}

	&.is-small {

		&:before {
			width: rem(5px);
			top: rem(10px);
			height: calc(100% - 1.3125rem);
		}
	}

	&.is-big {
		font-size: rem(32px);
		line-height: rem(32px);
		padding: 0 0 0 rem(30px);

		@include tablet {
			font-size: rem(48px);
			line-height: rem(48px);
		}

		@include desktop {
			font-size: rem(56px);
			line-height: rem(56px);
		}

		@include widescreen {
			font-size: rem(64px);
			line-height: rem(64px);
		}

		&:before {
			width: rem(10px);
			height: calc(100% - 0.625rem);
			top: rem(3px);

			@include tablet {
				top: rem(6px);
				height: calc(100% - 1.25rem);
			}
		}
	}

	&.is-shortcut {
		padding: 0 0 0 rem(26px);

		&:before {
			top: rem(1px);
			height: calc(100% - 0.8125rem);
		}
	}
}

.text-border-container {
	padding: rem(82px) rem(30px) rem(87px) rem(30px);

	@include tablet {
		padding: rem(82px) rem(40px) rem(87px) rem(40px);
	}

	@include desktop {
		display: block;
		padding: rem(82px) rem(60px) rem(87px) rem(60px);
	}

	@include widescreen {
		padding: rem(82px) rem(100px) rem(87px) rem(100px);
	}

	&.has-extra-padding-left {

		@include tablet {
			padding: rem(82px) rem(40px) rem(87px) rem(60px);
		}

		@include desktop {
			padding: rem(82px) rem(60px) rem(87px) rem(80px);
		}

		@include widescreen {
			padding: rem(82px) rem(100px) rem(87px) rem(120px);
		}
	}

	&.has-extra-padding-right {

		@include tablet {
			padding: rem(82px) rem(60px) rem(87px) rem(40px);
		}

		@include desktop {
			padding: rem(82px) rem(80px) rem(87px) rem(60px);
		}

		@include widescreen {
			padding: rem(82px) rem(120px) rem(87px) rem(100px);
		}
	}
}
