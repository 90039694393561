﻿@import '~bulma/sass/elements/image';

.image {

	&.is-text-column-image {
		margin: rem(14px) 0 rem(30px) 0;
	}

	&.is-text-block-image {
		float: right;
		width: 100%;
		margin: rem(14px) 0 rem(30px) 0;

		@include desktop {
			max-width: 50%;
			margin: rem(14px) 0 rem(20px) rem(100px);
		}
	}

	&.has-container-filling-image {

		@include desktop {
			position: relative;
			width: 100%;
			height: 100%;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				object-fit: cover;
				object-position: center;
			}
		}
	}

	&.has-placeholder {
		background: $primary url(../../../images/pattern.svg);
		background-size: 290px 168px;
	}

	&.is-video {
		position: relative;

		&:after {
			position: absolute;
			content: '';
			width: rem(80px);
			height: rem(57px);
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) scale(1);
			background: url(../../../images/play.svg);
			background-size: cover;
			transition: all 0.2s ease-in-out;

			@include tablet {
				width: rem(100px);
				height: rem(71px);
			}

			@include desktop {
				width: rem(120px);
				height: rem(85px);
			}
		}

		&:hover {

			&:after {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}