﻿.contact-block {
	position: relative;
	background: #fff;
	max-width: rem(452px);
	width: 100%;
	padding: rem(38px) rem(40px) rem(29px) rem(50px);
	margin: 0 auto 0 0;
	font-size: rem(32px);
	line-height: rem(32px);
	font-weight: bold;

	@include desktop {
		margin: 0 auto 0;
	}

	@include widescreen {
		margin: 0 0 0 auto;
	}

	h2 {
		color: $primary;
		font-weight: bold;
	}

	&:before {
		position: absolute;
		content: '';
		width: rem(10px);
		height: 100%;
		top: 0;
		left: 0;
		background: $secondary;
	}
}

.contact-block-content,
.contact-block-content a {
	color: $secondary;
}

.contact-block-content {
	margin: 0 0 rem(27px);
}