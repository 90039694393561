@import '~bulma/sass/components/level';

.level {

	&.is-legal-bar {
		color: rgba(#fff, 0.5);
		font-size: rem(12px);
		padding: rem(45px) 0 rem(80px);

		@include desktop {
			padding: rem(45px) 0 rem(14px);
		}

		a {
			color: rgba(#fff, 0.5);

			&:hover {
				text-decoration: underline;
			}
		}

		.level-item {
			margin-right: 1.5625rem;
			justify-content: flex-start;
		}
	}
}