$icon-arrow-down: '\ea01';
$icon-arrow-left: '\ea02';
$icon-arrow-right: '\ea03';
$icon-arrow-up: '\ea04';
$icon-check: '\ea05';
$icon-close: '\ea06';
$icon-scroll-down: '\ea07';
$icon-search: '\ea08';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-arrow-down:before { content: $icon-arrow-down; }
.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-arrow-right:before { content: $icon-arrow-right; }
.icon-arrow-up:before { content: $icon-arrow-up; }
.icon-check:before { content: $icon-check; }
.icon-close:before { content: $icon-close; }
.icon-scroll-down:before { content: $icon-scroll-down; }
.icon-search:before { content: $icon-search; }
