﻿$button-color: $primary;
$button-background-color: $secondary;

$button-border-color: $white;
$button-border-width: 0;

//$button-padding-vertical: $control-padding-vertical;
//$button-padding-horizontal: $control-padding-horizontal;

$button-hover-color: mix($primary, $white, 20%);
$button-hover-border-color: mix($primary, $white, 20%);

$button-focus-color: $button-hover-color;
$button-focus-border-color: $button-hover-border-color;

$button-active-color: $button-focus-color;
$button-active-border-color: $button-hover-border-color;

@import '~bulma/sass/elements/button';

.button {
	position: relative;
	z-index: 0;
	height: auto;
	padding: rem(11px) rem(30px) rem(10px) rem(40px);
	border: 0;
	border-radius: 0;
	font-size: rem(18px);
	font-weight: bold;
	letter-spacing: 0.02em;
	transition: all $speed ease-in-out;
	white-space: initial;
	overflow: hidden;
	text-align: left;

	@include desktop {
		white-space: nowrap;
	}

	&:before {
		position: absolute;
		content: '';
		z-index: -1;
		height: 100%;
		width: rem(5px);
		top: 0;
		left: 0;
		background: $primary;
		transition: all 0.2s ease-in-out;
	}

	&:after {
		position: absolute;
		content: '>';
		top: rem(11px);
		left: rem(24px);
	}

	&:hover {
		color: #fff;

		&:before {
			width: 100%;
		}
	}
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
	margin-right: 1.25rem;
}

