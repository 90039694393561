﻿.search-form {

}

.search-form-collapser {
	position: absolute;
	z-index: 1001;
	display: flex;
	align-items: center;
	width: 100%;
	height: rem(140px);
	padding: 0 rem(20px);
	top: 0;
	right: 0;
	background: $primary;
	visibility: hidden;
	opacity: 0;
	transition: all 0.25s ease-in-out;

	&.is-open {
		visibility: visible;
		opacity: 1;
	}

	@include tablet {
		padding: 0 rem(40px);
	}
}

.search-form-container {
	width: 100%;

	form {
		display: flex;
		align-items: center;
		width: 100%;

		.search-form-input {
			flex-grow: 1;
			width: auto;
			margin: 0 rem(20px);
		}
	}
}

.search-form-button,
.search-form-opener,
.search-form-closer {
	position: relative;
	border: none;
	background: $primary;
	width: rem(48px);
	height: rem(48px);
	cursor: pointer;
	color: #fff;
	text-indent: -99999px;
	overflow: hidden;

	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(20px);
		text-indent: 0;
	}
}

.search-form-button,
.search-form-opener {

	&:after {
		@include icon($icon-search);
	}
}

.search-form-closer {

	&:after {
		@include icon($icon-close);
	}
}

.search-form-opener {
	position: absolute;
	top: 50%;
	right: rem(20px);
	transform: translateY(-50%);

	@include tablet {
		right: rem(40px);
	}
}