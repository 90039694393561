﻿.search-results {
	font-size: rem(18px);

	li {
		border-bottom: 1px solid $primary;
		margin: 0 0 rem(40px);
	}

	li > a {
		display: block;
		overflow: hidden;
		padding: rem(32px) rem(20px) 0;

		&:hover {
			background: $section-background;
		}
	}

	img {
		margin: 0 0 rem(28px);
	}

	p {
		margin: 0 0 rem(28px);
	}

	table {

		td,
		th {
			padding: 0;

			&:first-child {
				width: rem(94px);
			}
		}
	}

	.content {
		margin-bottom: rem(28px);
	}
}
