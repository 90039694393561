﻿.filter {
	position: relative;
	z-index: 0;
	padding: rem(20px) rem(50px);
	background: $primary url(../../images/pattern.svg);
	background-size: rem(290px) rem(168px);
	margin: rem(-50px) 0 rem(50px);

	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, rgba($primary, 1) 0%, rgba($primary, 1) 50%, rgba($primary, 0) 100%);
	}

	form {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;

		.label {

			&:not(:last-child) {
				margin: rem(10px) rem(26px) rem(10px) 0;
			}
		}
	}

	.label {
		color: $secondary;
		font-size: rem(18px);
	}

	.select {
		width: 100%;
		max-width: rem(520px);
	}
}
