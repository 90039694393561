﻿.link {
	position: relative;
	font-size: rem(18px);
	line-height: rem(32px);
	font-weight: bold;
	color: $secondary;
	padding: 0 0 0 rem(14px);

	&:before {
		position: absolute;
		content: '>';
		top: rem(-1px);
		left: 0;
	}

	&:after {
		position: absolute;
		content: "";
		width: 100%;
		height: 2px;
		bottom: rem(5px);
		left: 0;
		background: $secondary;
		transform: scaleX(0);
		transform-origin: left;
		transition: all 0.2s ease-in-out;
	}

	&:hover {
		color: $secondary;

		&:after {
			transform: scaleX(1);
		}
	}

	&.is-big {
		font-size: rem(24px);

		&:before {
			top: rem(4px);
		}

		&:after {
			height: 3px;
		}
	}

	&.is-primary {
		color: $primary;

		&:after {
			background: $primary;
		}
	}
}

.link-container {
	position: absolute;
	width: 100%;
	left: 0;
	text-align: right;

	&.is-top {
		top: rem(35px);
	}

	&.is-bottom {
		bottom: 0;
	}
}