﻿.logo {
	position: absolute;
	z-index: 101;
	display: block;
	width: rem(198px);
	height: rem(87px);
	top: rem(27px);
	left: rem(20px);
	background: url(../../images/logo-dutch-horticulture.svg) no-repeat center;
	background-size: contain;

	@include widescreen {
		left: rem(120px);
	}
}

.logo-holland {
	position: absolute;
	display: none;
	z-index: 101;
	width: rem(80px);
	height: rem(80px);
	top: rem(32px);
	left: rem(240px);
	background: url(../../images/sticker.svg) no-repeat center;
	background-size: cover;

	@include tablet {
		display: block;
	}

	@include widescreen {
		left: rem(388px);
	}

	&.is-footer-log {
		position: relative;
		width: rem(240px);
		height: rem(70px);
		top: auto;
		left: auto;
		display: block;
	}
}

.logo-greenport {
	display: block;
	width: rem(240px);
	height: rem(88px);
	background: url(../../images/greenports.svg) no-repeat center;
	background-size: contain;
	margin: 0 auto;
}

.logo-china {
	display: block;
	float: left;
	width: rem(240px);
	height: rem(70px);
	background: url(../../images/in-china-logo.svg) no-repeat center;
	background-size: contain;
	margin: rem(19px) auto;
}

.logo-topsector {
	display: block;
	width: rem(240px);
	height: rem(100px);
	background: url(../../images/logo-topsector.png) no-repeat center;
	background-size: contain;
	margin: 0 auto;

	html[lang=en] & {
		background-image: url(../../images/logo-topsector-en.png);
	}
}

.logo-container {
	overflow: hidden;

	.logo-topsector,
	.logo-holland,
	.logo-greenport {
		margin: rem(19px) 0;

		@include tablet {
			float: left;
		}
	}
}
