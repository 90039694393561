﻿.call-to-action {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 0;
	height: rem(440px);

	@include mobile {
		height: rem(540px);
	}

	@include tablet {
		height: rem(640px);
	}

	h2 {
		font-size: rem(40px);
		line-height: rem(40px);

		@include desktop {
			font-size: rem(56px);
			line-height: rem(56px);
		}
	}

	img {
		position: absolute;
		z-index: -1;
		width: auto;
		max-width: initial;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.call-to-action-content {
	padding: rem(84px) 0 rem(90px) rem(28px);

	.text-border {
		margin-left: rem(-28px);
		margin-bottom: rem(23px);
	}
}