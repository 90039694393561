$footer-background-color: $primary;
$footer-color: #fff;
$footer-padding: rem(84px) 0 0;

@import '~bulma/sass/layout/footer';

.footer {
	position: relative;
	z-index: 0;
	background: $primary url(../../../images/pattern.svg);
	background-size: rem(290px) rem(168px);
		
	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, rgba($primary, 1) 0%, rgba($primary, 1) 50%, rgba($primary, 0) 100%);
	}
}