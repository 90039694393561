$section-padding: 0;
$section-padding-medium: 0;
$section-padding-large: 0;

$section-background: #e8eaf1;

@import '~bulma/sass/layout/section';

.section {
	position: relative;
	overflow: hidden;
	z-index: 0;
	margin: 0 0 rem(50px);
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	flex-direction: column;

	&.has-background {
		background: url(../../../images/pattern-white.svg) $section-background;
		background-size: 290px 168px;

		&:before,
		&:after {
			position: absolute;
			z-index: -1;
			content: '';
			width: 50%;
			height: 100%;
			top: 0;
		}

		&:before {
			left: 0;
			background: linear-gradient(90deg, rgba($section-background, 1) 0%, rgba($section-background, 0.5) 50%, rgba($section-background, 0) 100%);
		}


		&.has-right-gradient {

			&:after {
				right: 0;
				background: linear-gradient(90deg, rgba($section-background, 0) 0%, rgba($section-background, 0.5) 50%, rgba($section-background, 1) 100%);
			}
		}
	}

	.title {
		order: -1;

		@include desktop {
			order: 0;
		}
	}
}

.section.has-padding,
.section-content {
	padding: rem(86px) rem(40px) rem(80px) rem(40px);

	@include desktop {
		display: block;
		padding: rem(86px) rem(60px) rem(80px) rem(60px);
	}

	@include widescreen {
		padding: rem(86px) rem(100px) rem(80px) rem(100px);
	}

	&.has-extra-padding-left {

		@include desktop {
			padding: rem(86px) rem(60px) rem(80px) rem(80px);
		}

		@include widescreen {
			padding: rem(86px) rem(100px) rem(80px) rem(120px);
		}
	}

	&.has-extra-padding-right {

		@include desktop {
			padding: rem(86px) rem(80px) rem(80px) rem(60px);
		}

		@include widescreen {
			padding: rem(86px) rem(120px) rem(80px) rem(100px);
		}
	}
}