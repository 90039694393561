﻿$table-color: $text;
$table-background-color: transparent;

$table-cell-border: 1px solid #c2c2c2;
$table-cell-border-width: 0 0 1px;
$table-cell-padding: 0.125rem 0.625rem 0.375rem;
$table-cell-heading-color: $primary;

//$table-head-cell-border-width: 0 0 2px !default
$table-head-cell-color: $primary;
//$table-foot-cell-border-width: 2px 0 0 !default
//$table-foot-cell-color: $text-strong !default

//$table-head-background-color: transparent !default
//$table-body-background-color: transparent !default
//$table-foot-background-color: transparent !default

//$table-row-hover-background-color: $scheme-main-bis !default

//$table-row-active-background-color: $primary !default
//$table-row-active-color: $primary-invert !default

//$table-striped-row-even-background-color: $scheme-main-bis !default
//$table-striped-row-even-hover-background-color: $scheme-main-ter !default*/

@import '~bulma/sass/elements/table';

.table {

	thead {

		th,
		td {
			border-color: $primary;
			min-width: 10rem;
			font-size: 0.875rem;
			font-weight: bold;
		}
	}

	th,
	td {
		font-size: 0.75rem;
		font-weight: normal;
		color: $text;
	}
}

.table.shows-information {

	td,
	th {
		border: 0;
		padding: 0;
		color: #fff;
		font-size: rem(16px);
		line-height: rem(32px);

		a {
			color: #fff !important;
		}


		&:first-child {
			width: rem(124px);
		}
	}
}