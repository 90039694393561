﻿$notification-radius: 0;
$notification-padding: rem(34px) rem(40px) rem(20px) rem(40px);

@import '~bulma/sass/elements/notification';

.notification {
	display: flex;
	font-weight: bold;
	width: 100%;
	flex-wrap: wrap;
	border-left: rem(10px) solid $secondary;
	cursor: pointer;

	strong {
		font-size: rem(24px);
		line-height: rem(24px);
	}

	span {
		display: block;
		font-size: rem(18px);
		line-height: rem(24px);
		color: $secondary;
		margin: 0 0 rem(10px);
	}
}

.toast-top-right {
	position: fixed;
	top: rem(180px);
	right: rem(40px);
	z-index: 1000;
	width: 100%;
	max-width: rem(370px);
}

.toast-close-button {
	display: none;
}

.toast-progress {
	position: absolute;
	max-width: rem(250px);
	height: 2px;
	bottom: rem(20px);
	left: rem(40px);
	background: rgba($primary, 0.15);
}