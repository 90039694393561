@import '~bulma/sass/form/select';

.select {
	font-size: rem(18px);

	select {
		height: 100%;
	}

	&:not(.is-multiple):not(.is-loading)::after {
		content: '>';
		font-size: rem(8);
		border: none;
		margin: 0;
		transform: rotate(90deg) translateY(-50%);
		font-weight: bold;
	}
}