﻿.shortcuts {
	width: 100%;
	max-width: rem(560px)
}

.shortcut {
	padding: rem(18px) 0 rem(8px) 0;
	border-bottom: 1px solid rgba($secondary, 0.2);
	display: block;

	.title {
		color: #fff;
		font-size: rem(24px);
		line-height: rem(24px);

		&:not(:last-child) {
			margin-bottom: rem(-1px);
		}
	}

	&.is-big {
		position: relative;
		padding: rem(36px) rem(50px) rem(29px);
		border-bottom: none;

		.title {
			font-size: rem(32px);
			line-height: rem(32px);
			margin: 0 0 rem(22px);

			strong {
				color: $secondary;
			}
		}

		&:before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			opacity: 0.9;
			background: $primary;
		}

		&:after {
			position: absolute;
			content: '';
			width: rem(10px);
			height: 100%;
			background: $secondary;
			top: 0;
			left: 0;
		}
	}
}
