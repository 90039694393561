$input-border-color: #fff;
$input-height: rem(48px);
$input-shadow: none;
$input-placeholder-color: #333333;

$input-hover-border-color: $secondary;

$input-focus-color: $text;
$input-focus-border-color: $secondary;
$input-focus-box-shadow-size: none;
$input-focus-box-shadow-color: none;

$input-radius: 0;

@import '~bulma/sass/form/shared';


.input {
	height: $input-height;
	width: 100%;
	border-left: rem(6px) solid $secondary;
}

.select,
.select select {
	width: 100%;
}

.control .select {
	border-radius: $radius;

	select {
		font-size: 1rem;
	}
}

.input,
.select select,
.textarea {
	border-left: rem(6px) solid $secondary;
}

.input::placeholder, .select select::placeholder, .textarea::placeholder {
	opacity: 1;
}

form {

	.label {
		font-size: rem(18px);
	}

	.field, .field:not(:last-child) {
		margin: rem(22px) 0 0;
	}

	.label:not(:last-child) {
		margin: 0 0 rem(10px);
	}
}