﻿@import '~bulma/sass/elements/content';

.content {

	p,
	dl,
	ol,
	ul,
	blockquote,
	pre,
	table {

		&:not(:last-child) {
			margin-bottom: rem(34px);
		}
	}

	&:not(:last-child) {
		margin-bottom: rem(30px);
	}

	&:last-child {
		margin-bottom: rem(10px);
	}

	.subtitle {
		font-size: rem(18px);
		line-height: rem(32px);

		&:not(:first-child) {
			margin-top: rem(33px);
		}

		&:not(:last-child) {
			margin-bottom: 0;
		}
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}