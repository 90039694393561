﻿.pagination-container {
	display: flex;
	width: 100%;
	align-content: center;
	margin: rem(16px) 0 rem(50px);

	.pagination {
		margin: 0 auto;
	}

	a {
		@extend .button;
	}
}

.pagination-container-search {

	.pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}

	li a,
	li span {
		padding: rem(10px);
	}

	a:hover {
		text-decoration: underline;
	}
}