﻿$mobile: 432px !default;

@mixin desktop-and-hover {
	@media screen and (min-width: $desktop) and (hover: hover) {

		body:not(.headroom--not-top) & {
			@content;
		}
	}
}

@mixin tablet-and-widescreen {
	@media screen and (min-width: $widescreen) and (hover: hover) {

		body:not(.headroom--not-top) & {
			@content;
		}
	}
}




@mixin mobile {
	@media screen and (min-width: $mobile), print {
		@content;
	}
}