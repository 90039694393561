﻿$title-color: $text;
$title-weight: 700;

.title {
	position: relative;
	white-space: normal;

	&.is-1 {
		text-align: center;
		margin: 0 0 rem(71px);
	}

	&.is-2 {
		margin: 0 0 rem(20px);
	}

	&.has-text-centered {
		width: 100%;
	}
}

.title-scroll-down {
	position: absolute;
	display: none;
	width: rem(32px);
	height: rem(32px);
	bottom: rem(-45px);
	left: 50%;
	transform: translateX(-50%);
	text-indent: -99999px;
	overflow: hidden;

	@include tablet {
		display: block;
	}

	&:before {
		@include icon($icon-scroll-down);
		position: absolute;
		color: $secondary;
		top: 0;
		left: 0;
		text-indent: 0;
		font-size: rem(32px);
	}
}

@import '~bulma/sass/elements/title';
