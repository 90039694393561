﻿$navbar-background-color: none;
$navbar-box-shadow-size: none;
$navbar-box-shadow-color: none;
$navbar-height: 0;
$navbar-padding-vertical: 0;
$navbar-padding-horizontal: 0;
//$navbar-z: 30 !default
//$navbar-fixed-z: 30 !default

$navbar-item-color: $secondary;
$navbar-item-hover-color: $primary;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: #fff;
$navbar-item-active-background-color: transparent ;
//$navbar-item-img-max-height: 1.75rem !default

//$navbar-burger-color: $navbar-item-color !default

$navbar-tab-hover-background-color: $primary;
//$navbar-tab-hover-border-bottom-color: $link !default
//$navbar-tab-active-color: $link !default
$navbar-tab-active-background-color: $secondary;
//$navbar-tab-active-border-bottom-color: $link !default
//$navbar-tab-active-border-bottom-style: solid !default
//$navbar-tab-active-border-bottom-width: 3px !default

$navbar-dropdown-background-color: $secondary;
$navbar-dropdown-border-top: none;
//$navbar-dropdown-offset: -4px !default
//$navbar-dropdown-arrow: $link !default
$navbar-dropdown-radius: 0;

$navbar-dropdown-boxed-radius: 0;
$navbar-dropdown-boxed-shadow: none;

$navbar-dropdown-item-hover-color: $primary;
$navbar-dropdown-item-hover-background-color: none;
$navbar-dropdown-item-active-color: #fff;
$navbar-dropdown-item-active-background-color: none;

//$navbar-divider-background-color: $background !default
//$navbar-divider-height: 2px !default

$navbar-bottom-box-shadow-size: none;

@import '~bulma/sass/components/navbar';


.navbar {
	position: fixed;
	z-index: 102;
	width: rem(320px);
	height: 100vh;
	top: 0;
	right: 0;
	visibility: hidden;
	opacity: 0;
	background: $secondary;
	transition: opacity 0.2s ease-in-out;
	overflow-y: scroll;

	@include tablet {
		width: rem(400px);
	}

	@include tablet-and-widescreen {
		max-width: 40%;
	}

	@include desktop-and-hover {
		position: absolute;
		width: 100%;
		max-width: 40%;
		height: auto;
		top: 50%;
		right: rem(108px);
		transform: translateY(-50%);
		font-size: rem(18px);
		background: none;
		visibility: visible;
		opacity: 1;
		overflow-y: initial;
	}

	@include widescreen {

		body:not(.headroom--not-top) & {
			max-width: 45%;
		}
	}

	@include fullhd {

		body:not(.headroom--not-top) & {
			max-width: 50%;
		}
	}

	&.is-open {
		visibility: visible;
		opacity: 1;
	}

	.navbar-end {
		flex-wrap: wrap;
	}

	.navbar-menu {
		display: block;
		padding: rem(138px) rem(40px) rem(150px);
		box-shadow: none;
		flex: 1;

		@include desktop-and-hover {
			padding: 0;
		}

		.navbar-end > .navbar-item,
		.navbar-end > .navbar-item > .navbar-link {

			&:hover > span,
			&.is-active > span {
				position: relative;

				&:after {
					position: absolute;
					content: '';
					width: 100%;
					height: 1px;
					bottom: rem(3px);
					left: 0;
					background: $primary;

					@include desktop-and-hover {
						background: $secondary;
					}
				}
			}
		}

		.navbar-item {
			display: block;
			border-top: 1px solid rgba($primary, 0.2);
			padding: rem(14px) rem(13px);
			width: 100%;

			@include desktop-and-hover {
				float: left;
				border: none;
				width: auto;
			}

			&.has-dropdown:not(.is-language) {
				padding: 0;

				@include desktop-and-hover {

					&:hover {
						background: $secondary;

						> .navbar-item, .navbar-link {
							color: $primary;
						}

						.navbar-dropdown {
							display: block;
						}
					}
				}

				.navbar-dropdown {
					display: none;
					padding: 0;
					box-shadow: none;
					position: static;
					background: transparent;

					@include desktop-and-hover {
						position: absolute;
						background: $secondary;
					}
				}

				.navbar-link {
					position: relative;
					padding: rem(14px) rem(13px);
					width: 100%;

					&:before {
						@include icon($icon-arrow-down);
						position: absolute;
						width: rem(55px);
						height: rem(55px);
						padding: rem(23px) 0 0;
						border-left: 1px solid #13cd8f;
						top: 0;
						right: 0;
						text-align: center;
						font-size: rem(10px);

						@include desktop-and-hover {
							display: none;
						}
					}

					@include desktop-and-hover {
						padding: rem(14px) rem(33px) rem(14px) rem(13px);

						&:after {
							@include icon($icon-arrow-down);
							position: absolute;
							top: rem(26px);
							right: rem(18px);
							font-size: rem(8px);
						}
					}
				}

				&.is-open {
					background: rgba($primary, 0.08);

					.navbar-link {
						background: transparent;
						border-bottom: 1px solid rgba($primary, 0.2);

						@include desktop-and-hover {
							border-bottom: none;
						}

						&:before {
							@include icon($icon-arrow-up);
						}
					}

					.navbar-dropdown {
						display: block;
					}
				}
			}

			&.is-language {
				padding: rem(32px) rem(10px) 0;

				@include desktop-and-hover {
					padding: rem(14px) rem(20px);
					margin-left: rem(20px);

					&:hover {
						background: $secondary;
						color: $primary;

						.navbar-link {
							color: $primary;
						}

						.navbar-dropdown {
							display: block;
						}
					}
				}

				> .navbar-link {
					padding: 0;
					display: none;

					@include desktop-and-hover {
						display: block;
					}
				}

				.navbar-dropdown {
					position: static;
					display: block;
					box-shadow: none;

					@include desktop-and-hover {
						position: absolute;
						display: none;
					}
				}

				.navbar-item {
					width: rem(56px);
					height: rem(56px);
					border-radius: 50%;
					background: rgba($primary, 0.06);
					text-indent: -9999px;
					overflow: hidden;
					float: left;
					margin: 0 rem(10px);

					&:after {
						position: absolute;
						content: attr(data-name);
						text-indent: 0;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						text-transform: uppercase;
					}

					&:hover,
					&.is-active {
						background: $primary;
						color: $secondary;
					}

					@include desktop-and-hover {
						position: static;
						width: auto;
						height: auto;
						border-radius: 0;
						background: none;
						text-indent: 0;
						float: none;
						margin: 0;

						&:after {
							display: none;
						}
					}
				}

				&:before {

					@include desktop-and-hover {
						position: absolute;
						content: '';
						width: 1px;
						height: rem(17px);
						top: rem(20px);
						left: rem(-10px);
						background: $secondary;
					}
				}
			}
		}

		.navbar-item, .navbar-link {
			font-size: rem(18px);
			color: $primary;

			@include desktop-and-hover {
				color: $secondary;
			}
		}

		.navbar-dropdown a.navbar-item {
			font-size: rem(16px);
			color: $primary;
			padding: rem(4px) rem(20px);
			border: none;

			&:hover,
			&.is-active {
				color: #fff;
			}

			&:first-child {
				padding-top: rem(15px);
			}

			&:last-child {
				padding-bottom: rem(15px);
			}

			@include desktop-and-hover {
				color: $primary;
				width: 100%;

				&:hover,
				&.is-active {
					color: #fff;
				}
			}
		}
	}
}

.navbar-background {
	position: fixed;
	z-index: 101;
	content: '';
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background: rgba($primary, 0.8);
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.2s ease-in-out;

	&.is-active {
		opacity: 1;
		visibility: visible;
	}
}

body.headroom--top {

	@media screen and (min-width: $desktop) and (hover: hover) {

		.navbar-background.is-active {
			opacity: 0;
			visibility: hidden;
		}
	}
}