.header {
	position: relative;
	min-height: rem(140px);
	z-index: 3;
	background: $primary url(../../../images/pattern.svg);
	background-size: rem(290px) rem(168px);

	&:after {
		position: absolute;
		z-index: -1;
		content: '';
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: linear-gradient(90deg, rgba($primary, 1) 0%, rgba($primary, 1) 50%, rgba($primary, 0) 100%);
	}

	> .container {
		padding-top: rem(140px);
	}
}

.header-container {
	position: relative;
	min-height: rem(140px);
}

.header-image {
	position: relative;
	z-index: 0;
	height: rem(200px);

	@include mobile {
		height: rem(260px)
	}

	@include tablet {
		height: rem(360px)
	}

	&.is-large {
		display: flex;
		flex-direction: column;
		min-height: calc(100vh - 200px - 190px);
		height: auto;

		@include desktop {
			min-height: calc(100vh - 200px - 140px);
		}

		.shortcuts {
			align-self: flex-end;
			width: 100%;
			max-width: 100%;
		}
	}

	img {
		position: relative;
		z-index: -1;
		min-width: 100%;
		width: auto;
		max-width: initial;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		object-fit: cover;
		object-position: center;
		vertical-align: bottom;

		@include tablet {
			position: absolute;
		}
	}
}

.header-slogan-container {
	position: absolute;
	display: flex;
	height: 280px;
	top: 0;
	left: 0;

	@include tablet {
		height: rem(380px);
	}

	@include tablet {
		position: static;
		flex-grow: 1;
		height: auto;
	}
}

.header-slogan {
	display: block;
	font-size: rem(48px);
	line-height: rem(48px);
	text-shadow: 0px 0px 30px rgba($primary, 1);
	color: #fff;
	width: 100%;
	max-width: rem(800px);
	padding: rem(84px) rem(20px) rem(88px);
	margin: 0 auto;
	align-self: center;
	text-align: center;
	font-weight: bold;

	@include tablet {
		font-size: rem(56px);
		line-height: rem(56px);
	}
}