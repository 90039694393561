﻿$modal-background-background-color: rgba($primary, 0.8);

$modal-card-head-radius: 0;

@import '~bulma/sass/components/modal';

.model {
	cursor: initial;
}

.modal-card-body {
	position: relative;
	padding: rem(44px) rem(20px) rem(29px);

	@include tablet {
		padding: rem(44px) rem(50px) rem(29px);
	}

	button {
		position: absolute;
		width: rem(32px);
		height: rem(32px);
		border: 0;
		top: rem(10px);
		right: rem(10px);
		text-indent: -9999px;
		background: #fff;
		color: $primary;
		cursor: pointer;
		transition: all 0.2s ease-in-out;

		&:after {
			@include icon($icon-close);
			position: absolute;
			top: 50%;
			left: 50%;
			text-indent: 0;
			font-size: rem(12px);
			transform: translate(-50%, -50%);
		}

		&:hover {
			background: $primary;
			color: #fff;
		}
	}

	table {

		td,
		th {
			padding: 0;

			&:first-child {
				width: rem(94px);
			}
		}
	}
}

.modal-card {
	width: 100% !important;
	max-width: rem(980px);
	padding: 0 rem(20px) rem(80px);

	@include tablet {
		padding: 0 rem(80px);
	}
}

.modal-prev,
.modal-next {
	position: absolute;
	width: rem(40px);
	height: rem(40px);
	bottom: rem(20px);
	background: #fff;
	border-radius: 50%;
	text-indent: -9999px;
	transition: all 0.2s ease-in-out;
	cursor: pointer;

	@include tablet {
		top: 50%;
		bottom: auto;
		transform: translateY(-50%);
	}

	&:after {
		position: absolute;
		text-indent: 0;
		font-size: rem(12px);
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		background: $primary;
		color: #fff;
	}
}

.modal-prev {
	left: rem(20px);

	&:after {
		@include icon($icon-arrow-left);
		left: rem(14px);
	}
}

.modal-next {
	right: rem(20px);

	&:after {
		@include icon($icon-arrow-right);
		right: rem(14px);
	}
}