﻿$card-shadow: none;

$card-header-background-color: $primary;

@import '~bulma/sass/components/card';

.card {
	position: relative;
	display: block;

	&:before {
		position: absolute;
		z-index: 2;
		content: '';
		width: rem(10px);
		height: rem(100%);
		top: 0;
		left: 0;
		background: $secondary;
	}
}

.card-header {
	display: block;
	padding: rem(69px) rem(30px) rem(30px) rem(40px);

	@include widescreen {
		padding-left: rem(60px);
		padding-right: rem(50px);
	}

	.title {
		color: #fff;
		font-size: rem(32px);
		line-height: rem(32px);

		@include tablet {
			font-size: rem(48px);
			line-height: rem(48px);
		}
	}
}

.card-header-content {
	color: $secondary;
	font-size: rem(24px);
	line-height: rem(32px);
	margin: rem(-20px) 0 rem(24px) 0;
}

.card-image {
	position: relative;

	&:before {
		position: absolute;
		z-index: 1;
		display: block;
		content: '';
		width: 100%;
		height: rem(170px);
		top: 0;
		left: 0;
		background: linear-gradient(180deg, rgba($primary, 1) 0%, rgba($primary, 0) 100%);
	}
}