﻿$primary: #1a3374;
$primary-contrast: #f2f3f7;
$secondary: #11f396;

// Invert colors
$primary-invert: findColorInvert($primary);

// General colors
$background: #fff;

$border: rgba(#fff, 0.2);
//$border-hover: $grey-light !default
//$border-light: $grey-lightest !default
//$border-light-hover: $grey-light !default

// Text colors
$text-strong: inherit;
$text: $primary;

// Link colors
$link: $primary;
$link-visited: $link;

$link-hover: $primary;
$link-hover-border: inherit;

$link-focus: inherit;
$link-focus-border: inherit;

$link-active: inherit;
$link-active-border: inherit;

// Typography

@import '~bulma/sass/utilities/derived-variables';